/* Features */
.feature {
    gap: 4rem 3rem;
    margin: 6rem 0;
  }
  
  .feature:first-of-type {
    margin-top: 6rem;
  }
  
  .feature__heading {
    margin: 1rem 0 3rem;
    color: rgb(33, 19, 186);
    font-style: italic;
    font-size: 2rem;
    text-align: center;
  
  }
  
  .feature__content{
   color: black;
   margin-top: 2rem;
   font-family: "Nanum Gothic", sans-serif;
   font-size: 1.6rem;
  }
  

  
  @media screen and (min-width:480px){

  

   .feature__heading{
    font-size: 2.5rem;
   }



   .feature__content{
    font-size: 1.8rem;
   }
  }


  @media  screen and (min-width:768px) {
    .feature__heading{
        font-size: 3rem;
       }
    
     
       .feature__content{
        font-size: 2rem;
       }
         
  }
  
  
 @media screen and (min-width:1024px) {
  .feature__flex{
    display: flex;
    gap:30px;
    margin: 0 5rem;
    align-items: center;    

    .feature__content{
      width: 100%;
    }
 }
}

 @media screen and (min-width:1440px){
     
  .feature__heading{
    font-size: 3.5rem;
   }

 
   .feature__content{
    font-size: 2.5rem;
   }
 }

 @media screen and (max-width: 930px) and (max-height: 428px) and (orientation: landscape) {
   
  .feature{
    margin: 7rem 0;
}
  

  .feature__flex{
    display: flex;
    gap:30px;
    margin: 0 ;
    align-items: center;    

  }

  .feature__content{
    margin-top: 0;
    flex:1;
    font-size: 1.8rem;
  }

  .feature__picture{
    flex:1;
  }
 }