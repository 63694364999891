
.services{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services__mainTitle{
  color: black;
    text-align: center;
    font-size: 2.5rem;
    margin: 3rem 0 4rem;
}

.services__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6rem;

    
    .services__image{
      margin: 0 auto;
      width: 80%;
      margin-bottom: 1.5rem;
    }

    .services__content{
      margin: 0 auto;
      width: 90%;
      font-size: 1.6rem;
      text-align: justify;
      hyphens: auto;
      font-family: "Nanum Gothic", sans-serif;

      .services__title{
        color: black;
        font-size: 2rem;
        text-align: center;
      }
    }

}

@media screen and (min-width:480px) {


  .services__container{
   max-width: 450px;
   margin-bottom: 7rem;
  }
  
}

@media screen and (min-width:768px) {

  .services__mainTitle{
    font-size: 3rem;
    margin-bottom: 4rem
  }

  .services__container{
    flex-direction: row;
    width: 90%;
    max-width: 850px;
    gap: 20px;
    margin: 0 auto 10rem auto;
    
    

    .services__image{
      object-fit: contain; 
      max-width: 350px;
     }


    .services__content{
      font-size: 1.5rem;

      .services__title{
        margin-bottom: 3rem;
      }
     }
  }
  
}

@media screen and (min-width:1024px) {
  .services__mainTitle{
    font-size: 4rem;
    margin: 5rem 0;
  }

  .services__container{
    max-width: 1000px;

 

    .services__image{ 
      max-width: 450px;
     }

    .services__content{
      font-size: 1.8rem;

      
     }
  
    }
  }

  @media screen and (min-width:1280px){
  
    .services__container{
      max-width: 1200px;
  
      .services__image{ 
        max-width: 550px;
       }
  
      .services__content{
        font-size: 2rem;

        .services__title{
          font-size: 2.5rem;
        }
       }
    }
  }