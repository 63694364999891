.donate_Section{
    display: flex;
    justify-content: center; /* Horizontal in der Mitte zentrieren */
    align-items: center; /* Vertikal in der Mitte zentrieren */
    margin:40px;
    gap:4rem;
    width: 90%;
   
    .donate_Heading{
      text-align: center;
      font-size: 2rem;
      font-family: 'Edu TAS Beginner';    
  }
  
  .donate_Heading_Subline{
    font-size: 1.4rem;
    font-family: 'Edu TAS Beginner', cursive;
    color: white; /* Schriftfarbe auf Weiß setzen */
    animation: glowing 2s linear infinite; /* CSS-Animation hinzufügen */
    
    @keyframes glowing {
      0% {
        color: white; /* Ausgangsfarbe (Weiß) */
      }
      50% {
        color: rgb(255, 150, 13);/* Hellere Goldfarbe in der Mitte der Animation */
      }
      100% {
        color: white; /* Zurück zur Ausgangsfarbe (Weiß) */
      }
    }
  }

  .donate_Button {
    font-family: 'Edu TAS Beginner', cursive;
    display: inline-block;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 30%;  
    align-items: center;
    justify-content: center;
    padding:6px;
    font-size: 14px;
    margin-left: 10px;
    background-color:   rgb(255, 150, 13);
  
    &:hover {
        background-color: #ffd700; /* Hellere Goldfarbe auf Hover setzen */
        box-shadow: 0 0 10px #ffd700; /* Leichter Schatten hinzufügen */
    }
  }
}


.paypal_buttonSection{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  margin-right: 20px;
  

  .paypal_Button_Wrapper {
    width: 100%; 
    margin-top: 20px;
  }


  .donation_Label{
    color: white;
    font-size: 1.5rem;
  }


/* Slider Styling */
  .slider-container {
    margin: 20px 0;
    text-align: center;
  }
  
  .horizontal-slider {
    width: 100%;
    height: 10px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .track {
    background: #fff;
    height: 100%;
  }
  
  .thumb {
    height: 40px;
    width: 40px;
    background-color: #0070BA; // Pinker Cursor
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    top: -15px; // Positionierung für das mittige Ausrichten

 
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
  }

  /* Paypal Component Styling */
  iframe[name="pwpp"]:not([style="position: absolute; visibility: hidden;"]) .paypal-button-fields .paypal-button-fields-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  /* Stil für die erste Spalte */
  iframe[name="pwpp"]:not([style="position: absolute; visibility: hidden;"]) .paypal-button-fields .paypal-button-fields-wrapper > div:nth-child(-n+3) {
    width: 50%;
    box-sizing: border-box;
    padding-right: 10px;
  }

  /* Stil für die zweite Spalte */
  iframe[name="pwpp"]:not([style="position: absolute; visibility: hidden;"]) .paypal-button-fields .paypal-button-fields-wrapper > div:nth-child(n+4) {
    width: 50%;
    box-sizing: border-box;
    padding-left: 10px;
  }


  .close_Button{
    background-color:   rgb(255, 150, 13);
    height: 40px;
    justify-content: center;
    padding: 0 20px;
    font-size: 18px;
    margin:0 auto;
    width: 100%;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  }

}




@media screen and (min-width:480px){
   .donate_Button{
    width:40%;
    max-width: 160px;
   }

   .paypal_buttonSection{
    max-width: 200px;
   }
}

@media screen and (min-width: 768px){
   .donate_Section{
    min-height: 200px;
    gap:8rem;

    .donate_Heading{
      font-size: 2.5rem;
     }
  
     .donate_Heading_Subline{
      font-size: 1.8rem;
     }

     .donate_Button{
      font-size: 22px;
     }
   }

   .paypal_buttonSection{
    max-width: 300px;


    .donation_Label{
      display: inline-block;
      margin-bottom: 2rem;
    }

    .horizontal-slider {
      display: inline-block;
      margin-bottom: 1.5rem;

      .thumb {
        height: 60px;
        width: 60px;
        top: -25px;
        font-size: 1.5rem;
     } 

    }

   
}
}

@media screen and (min-width: 1024px){

  .donate_Section{

    .donate_Heading{
      font-size: 2.8rem;
     }
  
     .donate_Heading_Subline{
      font-size: 2.0rem;
     }

     .donate_Button{
      max-width: 200px;
    }
  }
}

@media screen and (min-width: 1280px){
   .donate_Section{
    gap: 10rem;

    .donate_Heading{
      font-size: 3rem;
     }
  
     .donate_Heading_Subline{
      font-size: 2.2rem;
     }

     .donate_Button{
      font-size: 22px;
     }   
   }
}

@media screen and (min-width: 1440px){
  .donate_Section{
    gap: 12rem;
   }
}

@media screen and (max-width: 930px) and (max-height: 428px) and (orientation: landscape) {
  .paypal_buttonSection{
    max-width: 300px;
   }  
}
  