body {
  background: linear-gradient( to bottom,  #d6ecff,  #ffffff);
 
}

.volunteering__mainTitle{
  color: black;
  text-align: center;
  font-size: 2.5rem;
  margin: 3rem 0 4rem;
}

.volunteering__title{
  color: black;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.volunteering__container{
  display: flex;
  flex-direction: column;
  justify-content: center;

  .volunteering__image{
    margin: 0 auto;
    width: 80%;
    margin-bottom: 1.5rem;
  }

  .volunteering__content{
    margin: 0 auto 6rem;
    width: 90%;
    font-size: 1.6rem;
    text-align: justify;
    hyphens: auto;
    font-family: "Nanum Gothic", sans-serif
  }
}


@media screen and (min-width: 480px){
  .volunteering__image{
    max-width: 450px;
  }

  .volunteering__content{
    max-width: 450px;
  }
}

@media screen and (min-width:768px) {

  .volunteering__title{
    margin-bottom: 3rem;
  }

  .volunteering__mainTitle{
     font-size: 3rem;
  }

  .volunteering__container{
    flex-direction: row;
    width: 90%;
    max-width: 850px;
    gap: 20px;
    margin: 0 auto 10rem auto;


    .volunteering__image{
      object-fit: contain; 
      max-width: 350px;
     }


    .volunteering__content{
      font-size: 1.5rem;
     }
  }
  
}

@media screen and (min-width:1024px) {
  
  .volunteering__container{
    max-width: 1000px;

    .volunteering__image{ 
      max-width: 450px;
     }

    .volunteering__content{
      font-size: 1.8rem;

      .volunteering__title{
        font-size: 2.5rem;
      }
     }
  }
 
}

@media screen and (min-width:1280px){
  .volunteering__title{
    margin-bottom: 4rem;
  }

  .volunteering__container{
    max-width: 1200px;

    .volunteering__image{ 
      max-width: 550px;
     }

    .volunteering__content{
      font-size: 2rem;
     }
  }
}