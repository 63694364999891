.post-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: "Nanum Gothic", sans-serif;
  }
  
  body .post-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 50px;
    color: black;
  }
  
  .image {
    max-height: 300px;
    display: flex;
    overflow: hidden;
  }
  
  .image img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  
  .post-content {
    margin-top: 20px;
  }

  .postButton {
    width:150px;
    margin: 30px 0;
    background-color: rgb(255, 150, 13);
    font-weight: bold;
  }

  
  .postPagecontent p {
    margin-top: 30px;
    font-size: 1.6rem;
    line-height: 1.6;
    color: #333;
    text-align: center;
    hyphens: auto;
  }

  .edit{
    display: flex;
    justify-content: space-around;
  }

  body .head__title{
    margin-top: 35rem;
  }

  @media  (min-width:768px){

  .postButton{
    height: 35px;
    cursor: pointer;
  }
     
  .postPagecontent p  {
        font-size: 2rem;   
      }
    
  }