

.quote__Container{
   display: flex;
   margin-bottom: 20px;
   margin-top: -10px;
   padding-right: 20px;

.quote__line{
  color:rgb(125, 189, 253);
  margin-right: 5px;
  margin-top: 7px;
  width: 20px;
}

  .quote__author{
    font-size: 16px;
    color: black;
   }
}

.button-container{

.button-icon{
  height: 30px;
  width: 30px;
}

}


.button-container button {
    width:20%;
    display:inline;
  }

  #referencias{
    color: black;
  }


.quote__text{
  font-size: 14px;
}


/* Testimonials */
.testimonial {
  padding: 3rem;
  background-color: rgba(245, 222, 179, .5 ); 
  
}

.testimonial .quote {
  margin: 2rem 0 2rem 10px;
  font-size: 110%;
}

.testimonial__image {
  position: relative;
  margin: 0 3rem;
}

.testimonial__image > .icon-container {
  position: absolute;
  top: 3rem;
  right: -32px;
}


@media screen and (min-width:480px){

  .block__headline{
    font-size: 3rem;
  }
  

    .quote__text{
      font-size: 16px;
    }
  

  .button-container{
    margin-top: -6rem;
  }
  .prev-btn, .next-btn{
    font-size: 2.25rem;
  }

  .testimonial__image{
    max-width: 300px;
    margin: 0 auto;
  }
}



@media screen and (min-width: 768px) {

  .grid_References{
    justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

  .testimonial__image {
    margin: 0;
    max-width: 350px;
  }

  .testimonial .quote {
    margin: 5rem 0 0 6rem;
    font-size: 100%;
    line-height: 1.5;
    width: 80%;
  }
}

@media screen and (min-width: 1024px){
  .grid_References{
    flex-direction: row;
    margin: 0 50px;
  }

  .button-container{
    max-width: 35%;
  }
}

@media  screen and (min-width:1280px) {
  
}

/* Quotes */
.quote {
  font-size: 3rem;
  font-style: italic;
  color: var(--color-body-darker);
  line-height: 1.3;
}

.quote__text::before {
  content: open-quote;
}

.quote__text::after {
  content: close-quote;
}






@media screen and (min-width: 1024px) {
  .quote {
    font-size: 2rem;
  }

  .quote__author {
    font-size: 2.4rem;
  }

  .quote__organization {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 930px) and (max-height: 428px) and (orientation: landscape) {
  .grid_References{
    flex-direction: row;
    margin: 0;
  }
  .testimonial__image{
    max-width: 200px;
  }

  .card{
    height: 85vh;
  }
}