.heroContainer{
  background:linear-gradient(to bottom,  #d6ecff , #ffffff);
}

.btn--hero {
    background-color: rgb(222, 198, 11);
    color: black;
  }
  
  .hero__tagline {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 3rem 0 7rem;
    width: 300px;
    font-size: 2.2rem;
    background: linear-gradient(90deg, #D4AF37, #FFD700);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 8s linear infinite;
    display: inline-block;
}

#hero__heading{
  margin-top: 10vh;
  font-size: 4rem;
  margin-left:0px;
}
  



@media screen and (min-width: 480px) 

   {
 

    #hero__heading{
        margin-top: 10vh;
        margin-left: -5vh;
        font-size: 4.5rem;
        padding-left: 50px;
   }
      
   
      #hero__tagline{
        margin-left: -10vh;
        width: 500px;
    
        padding-left: 80px;
      }
   }



@media screen and (min-width:768px)  {


   #hero__tagline{
    width:700px;
   }
 
}

@media screen and (min-width:1024px){

  #hero__heading{
    font-size: 5rem;
  }

  .hero__tagline {
    font-size: 3rem

}
}





    

