.active{
    text-decoration: underline;
}

nav {
    background: linear-gradient(to bottom, #ffffff, #d6ecff);
    position: sticky;
    top: 0;
    padding: 0px 20px 0px 20px;
}

.nav__container{
    width: 90%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
}

.nav__brand {
    transform: translateY(5px);
    width: 70px;
    margin-right: 1rem;
  
    }

.nav__content-text{
    margin-bottom: 0;
font-weight: bold;
    font-size: 1.4rem;
    color: rgb(33, 19, 186)
}
      
      
.nav__context-span{
    color: rgb(255, 150, 13);
    }



nav ul {
    display: none;
    flex-direction: column;
    width: 50%;
}

nav ul li {
   list-style: none; 
   padding: 0px 10px;
   width: 100%;
   text-align: center;
}

nav ul li a {
    font-family: 'Lato', sans-serif;
    display: block;
    font-size: 1.5rem;
    text-decoration: none;
    color: rgb(26, 141, 248);
    padding: 0.5rem;
    margin: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    position: relative; /* Notwendig für die Linie */
    transition: background-color 0.7s ease;
}

nav ul li a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 5px;
    width: 100%;
    height: 4px; /* Dicke der Linie */
    background-color: transparent; /* Unsichtbar im normalen Zustand */
    transition: background-color 0.3s ease; /* Sanfter Übergang */
}

nav ul li a:not(.active):hover::after {
    background-color: #b9e0ff; /* Linie erscheint beim Hover */
}

nav ul li a:not(.active):hover {
    transform: scale(1.2); /* Skalierung beim Hover */
    transition: transform 0.3s ease; /* Sanfter Übergang für die Skalierung */
    
}

nav ul.open {
    display: flex;
}


nav .menu {
    display: flex;
    top:30px;
    position: absolute;
    right: 2rem;
    flex-direction: column;
    justify-content: space-between;
    width: 2.25rem;
    height: 2rem;
}

nav .menu span {
    height: 0.4rem;
    width: 100%;
    background-color: rgb(255, 150, 13);;
    border-radius: 0.2rem;
}

.nav__itemSocial{
    margin-top: 0px;
    }





@media screen and (min-width:480px) {
    .nav__container{
        max-width: 500px;
        width: 85%;
    
    }

    .nav__content-text{
        font-size: 1.8rem;
    }
}


@media screen and (min-width:768px){
    .nav__container{
        width:100%;
        max-width: 1000px;
    }

    nav .menu{
        display: none;
    }

    nav ul {
        display: flex;
        width: auto;
        flex-direction: row;
        white-space: nowrap;
    }

    nav ul li {
        padding: 0;
    }

    nav ul li a {
        font-size: 1.5rem;
    }

    .nav__brand{
        width: 100px;
    }
}

@media screen and (min-width:1024px){

    .nav__container{
        max-width: 1200px;
    }

    nav ul{
        max-width: 700px;
    }
    .nav__content-text{
        font-size: 2.0rem;
    }

    nav ul li a{
        font-size: 2rem;
    }

   
   
}

@media  screen and (min-width:1280px) {

    .nav__container{
        max-width: 1800px;
    }

    .nav__content-text{
        font-size: 2.5rem;
    }
   

    nav ul li a{
        font-size: 2.3rem;
    }
}

@media screen and (min-width:1440px){
    .nav__content-text{
        font-size: 2.5rem;
    }
}