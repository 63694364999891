.aboutUS__title{
    color: black;
    text-align: center;
    font-size: 2.5rem;
    margin: 3rem 0 4rem;
}

.aboutUS__container{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .aboutUS__image{
      margin: 0 auto;
      width: 80%;
      margin-bottom: 1.5rem;
    }

    .aboutUS__content{
      margin: 0 auto;
      width: 90%;
      font-size: 1.6rem;
      text-align: justify;
      hyphens: auto;
      font-family: "Nanum Gothic", sans-serif
    }
}

@media screen and (min-width: 480px){
  .aboutUS__image{
    max-width: 450px;
  }

  .aboutUS__content{
    max-width: 450px;
  }
}

@media screen and (min-width:768px) {

  .aboutUS__title{
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  .aboutUS__container{
    flex-direction: row;
    width: 90%;
    max-width: 850px;
    gap: 20px;
    margin: 0 auto 10rem auto;


    .aboutUS__image{
      object-fit: contain; 
      max-width: 350px;
     }


    .aboutUS__content{
      font-size: 1.5rem;
     }
  }
  
}

@media screen and (min-width:1024px) {
  .aboutUS__title{
    font-size: 4rem;
  }

  .aboutUS__container{
    max-width: 1000px;

    .aboutUS__image{ 
      max-width: 450px;
     }

    .aboutUS__content{
      font-size: 1.8rem;
     }
  }
 
}

@media screen and (min-width:1280px){
  .aboutUS__title{
    margin-bottom: 4rem;
  }

  .aboutUS__container{
    max-width: 1200px;

    .aboutUS__image{ 
      max-width: 550px;
     }

    .aboutUS__content{
      font-size: 2rem;
     }
  }
}