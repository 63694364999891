.posts {
    display: block; /* Testweise auf block setzen */
    max-width: 700px;           /* Maximale Breite des Containers */
    width: 100%;                /* Breite des Containers auf 100% setzen */
    margin: 0 auto;            /* Zentriert den Container horizontal */
    padding: 0 20px;          /* Padding von 100px links und rechts */
    box-sizing: border-box;
    min-height: 500px; /* so wird auch bei keinen vorhandenen Posts die Seite angezeigt */


    .projectListcontent{
      margin-top: 150px;
    }
  }

  .projects__title{
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(33, 19, 186);

  }

 .post:first-child .projects__title {
    margin-top: 350px;
  }


 .projects__content{
  margin: 0 auto;
  font-family: "Nanum Gothic", sans-serif;
  text-align: justify;
  hyphens: auto;
  font-size: 1.6rem;
 }

 @media screen and (min-width:480px) {
  .posts{
    padding: 0 50px;
  }
 }


 @media screen and (min-width:768px) {
  .projects__content{
    font-size: 2.2rem;
    margin-bottom: 5rem;
  }

  .projects__title{
    font-size: 3rem;
  }
 }


 @media screen and (min-width:1024px) {
  .posts{
   max-width: 800px;
  }
 }


 @media screen and (min-width:1280px) {
   .posts{
    max-width: 1000px;
   }

   .content{
    font-size: 2.4rem;
    margin-bottom: 7rem;
 }
}

@media screen and (max-width: 930px) and (max-height: 428px) and (orientation: landscape) {
   .posts{
    max-width: 2000px;
    margin: 0 3rem;
   }
 
  .content{
    display: flex;
    width: 100%;
    gap: 20px;
     
     .projects__title{
      flex: 0 0 60%;
      margin-top: 0px;
     }

     .projects__content{
      flex: 0 0 40%;
      font-size: 1.6rem;
      margin-bottom: 3rem;
     }
  }
}